import { NavLink } from "@remix-run/react"
import { HamburgerMenuIcon } from "@radix-ui/react-icons"
import clsx from "clsx"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "~/components/ui/sheet"

export const Logo = ({ className }) => {
  return (
    <a
      className={clsx(
        "logo text-5xl !leading-none tracking-tight text-black",
        `${className}`,
      )}
      href="/"
    >
      GOODWERK
    </a>
  )
}

export let HeaderNavMenu = () => {
  return (
    <header className="sticky top-0 z-20 mx-auto flex h-16 max-w-screen-xl items-center rounded-b-md border border-gray-200 bg-off-white px-4 drop-shadow-sm lg:justify-center lg:px-8">
      <nav
        className="hidden flex-1 items-center justify-between lg:flex"
        aria-label="Global"
      >
        <div className="flex max-w-md flex-1 items-center justify-center gap-x-12">
          <NavLink
            to={"/#what-we-do"}
            className="text-lg font-medium text-black"
            caseSensitive
            end
          >
            What We Do
          </NavLink>

          <NavLink
            to={"/#testimonials"}
            className="text-lg font-medium text-black"
            caseSensitive
            end
          >
            Testimonials
          </NavLink>
        </div>

        <div className="flex">
          <Logo />
        </div>

        <div className="flex max-w-md flex-1 items-center justify-center gap-x-12">
          <NavLink
            to={"/#clients"}
            className="text-lg font-medium text-black"
            caseSensitive
            end
          >
            Clients
          </NavLink>

          <NavLink
            to={"/contact"}
            className="rounded-lg border-2 border-amber-400 bg-amber-400 px-2 text-lg font-medium text-black"
            caseSensitive
            end
          >
            Contact Us
          </NavLink>
        </div>
      </nav>
      <Sheet>
        <nav className="flex flex-1 items-center justify-between lg:hidden">
          <Logo className="pt-1 !text-4xl" />
          <SheetTrigger asChild>
            <div className="flex lg:hidden">
              <button
                className="inline-flex items-center justify-center gap-x-2 rounded-md border-2 border-amber-400 p-2 text-black"
                type="button"
              >
                <span className="sr-only">Open main menu</span>
                <HamburgerMenuIcon className="size-6" aria-hidden="true" />
                <span className="font-sans text-base font-medium">Menu</span>
              </button>
            </div>
          </SheetTrigger>
        </nav>
        <SheetContent>
          <SheetHeader>
            {/* <SheetTitle>What We Do</SheetTitle> */}
            <SheetDescription>
              <div className="flex w-full flex-1 flex-col items-start gap-x-12 gap-y-6">
                <NavLink
                  to={"/#what-we-do"}
                  className="text-lg font-medium text-black"
                  caseSensitive
                  end
                >
                  What We Do
                </NavLink>

                <NavLink
                  to={"/#testimonials"}
                  className="text-lg font-medium text-black"
                  caseSensitive
                  end
                >
                  Testimonials
                </NavLink>

                <NavLink
                  to={"/#clients"}
                  className="text-lg font-medium text-black"
                  caseSensitive
                  end
                >
                  Clients
                </NavLink>

                <NavLink
                  to={"/contact"}
                  className="self-start rounded-lg border-2 border-amber-400 bg-amber-400 px-2 text-lg font-medium text-black"
                  caseSensitive
                  end
                >
                  Contact Us
                </NavLink>
              </div>
            </SheetDescription>
          </SheetHeader>
          <SheetFooter></SheetFooter>
        </SheetContent>
      </Sheet>
    </header>
  )
}

export let FooterNavMenu = () => {
  const menuItems = {
    legal: [
      { name: "Privacy Policy", href: "/privacy-policy" },
      { name: "Terms of Service", href: "/terms-of-service" },
    ],
  }

  return (
    <div className="mx-auto max-w-7xl bg-black pl-5 pr-6 lg:px-8">
      <footer
        aria-labelledby="footer-heading"
        className="relative flex items-start justify-between bg-black py-6 sm:items-center"
      >
        {/* <a href="/">
          <Logo />
        </a> */}
        <div>
          <ul className="flex flex-col gap-y-2 sm:flex-row sm:items-center sm:gap-x-10">
            {menuItems.legal.map((item) => (
              <li key={item.name}>
                <NavLink
                  to={item.href}
                  className="text-base font-medium leading-6 text-white"
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </footer>
    </div>
  )
}
