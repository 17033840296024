import { useRouteError } from "@remix-run/react"
import { Outlet } from "@remix-run/react"
import { HeaderNavMenu, FooterNavMenu } from "./nav-menu"

export const ErrorBoundary = () => {
  const error = useRouteError()

  return (
    <>
      <HeaderNavMenu />
      <div className="prose mx-auto flex min-h-svh max-w-md items-center">
        <p>{error?.message ? error.message : ""}</p>
      </div>
      <FooterNavMenu />
    </>
  )
}

export default function LandingLayout() {
  return (
    <>
      <HeaderNavMenu />
      <Outlet />
      {/* <FooterNavMenu /> */}
    </>
  )
}
